.logo {
  line-height: 0 !important;
  margin: 0 !important;
  padding: 0;
  text-align: right;
  z-index: 999;

  .logotype {
    width: 260px; }

  .logo--preload {
    display: none; }

}

/*
.logo--mobile {
  width: 20%;
  float: right;
  @media only screen and (max-width: $breakpoint-small) {
    display: block;
  }
  @media only screen and (min-width: $breakpoint-med) {
    display: none;
  }
}
*/
.logomark {
  height: 100%;
  width: auto;

  footer & {
    padding-top: 6px; }

}

footer {
   @media only screen and (max-width: $breakpoint-med) {
  text-align: center;
}
  p {
    margin: 0;
  }
}

.subfooter {
   @media only screen and (min-width: $breakpoint-large) {
    text-align: right;
  }
}

.alw-type {
  white-space: nowrap;

  .alw-type--plus {
    color: $primary;
    position: relative;
      top: -2px; }

}


// Home > Slider

.slider {
  height: 100vh;
  position: relative;
  width: 100%;
    min-width: 100%;
  /*
  margin: 0;
  min-height: 100%;
  padding: 0;
  //overflow: hidden;
*/
  .slider__img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    text-align: center;
    /*
    position: absolute;
    margin: 0;
    min-height: 100%;
    padding: 0;
      top: 0;
      left: 0;
    width: 100%;*/
  }
  .slider__title {
    color: #fff;
   position: absolute;
   width: 100%;
     bottom: 140px;
 @media only screen and (max-width: $breakpoint-med) {
  font-size: 2.8rem;
  line-height: 2.5rem;
     //bottom: 200px;
 }
 @media only screen and (max-width: $breakpoint-small) {
  font-size: 2.3rem;
 }
      }

  .slider__subtitle {
    color: $gray-lightest;
    font-weight: 400;
    letter-spacing: 0.04em;
   position: absolute;
   width: 100%;
      bottom: 100px;
   @media only screen and (max-width: $breakpoint-small) {
      bottom: 80px;
    }
    text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
    text-transform: uppercase;
  }
}

.slider__scroller {
  margin:0 auto;
  text-align: center;
  position: relative;
  top: -7rem;
  @media only screen and (max-width: $breakpoint-small) {
    top: -5rem;
  }
  img {
    width: 70px;
  }
  &--project {
   top:-10rem;
   height: 0px;
 }
 animation: 5s slider-bop ease-out infinite;
}
@keyframes slider-bop {
0% {
    transform: translateY(0%) scale(1);

  }
  5% {
    transform: translateY(20%) scale(1.2);
  }
  10% {
    transform: translateY(0%) scale(1);
  }
  15% {
    transform: translateY(20%) scale(1.2);
  }

  20% {

    transform: translateY(0%) scale(1);
  }
  100% {
    transform: translateY(0%) scale(1);

  }
}
// Interior Hero Image

.hero {
  display: block;
  width: 100%;

  img {
    max-width: $breakpoint-large;
    width: 100%; }

}
 @media only screen and (min-width: $breakpoint-med) {
.header--sub + .hero > img {
  margin-top: 61px;
}
}

// Studio > People > Person

.person {
  margin: 0;
  margin-bottom: 3rem !important;
  padding: 0; }

.person__headshot {

//  height: auto;
 // width: auto;
   @media only screen and (min-width: $breakpoint-med) {
    width: 100%;
}
}
.person__name {
  margin: 0;
  font-weight: 500;
}
.person__detail {
  margin: 0;
}


// Work > Filtering

.filtering {
  margin-top: 7%;
    @media screen and (max-width: $breakpoint-small) {
      margin-top: 0;
      height: 0;
      overflow: hidden;
          background-color: white;
              position: fixed;
    top: 100px;
    z-index: 999;
    transition: height .3s ease-in-out;
    }
    &.open {
      height: calc(100vh - 100px)
    }
}

.freeze {
  overflow: hidden;
}

.mobile-filtering {
    @media screen and (min-width: $breakpoint-small) {
      display: none;
    }
  h3 {
    margin: 0;
  }
}
.mobile-filtering-toggle:link {
  font-weight: 500;
  margin: .5rem 0;
  display: inline-block;
}
.filter {
  display: block;
  float: left;
    @media screen and (min-width: $breakpoint-med) {
  margin-right: 100px;
}
  padding: 0 20px 10px 20px;

  &:not(:first-child) {
    @media screen and (min-width: $breakpoint-small) {
    border-left: 1px solid $gray-lighter;
  }
  }

  .filter__list {
    margin: 0;
    padding: 0; }

  .filter__list--columns {
      -moz-column-count: 2;
      -webkit-column-count: 2;
    column-count: 2; }

  h3 {
    margin: 0 0 10px 0;
    padding: 0;
    display: inline-block;
    width: 100%; }

  .filter__item {
    padding: 0 0 6px 0;
    margin-right: 10px;

    a {
      color: $gray-regular;

      &:hover {
        color: $gray-regular; }

      &.active {
        @include transition(color 0.3s ease);

        color: $gray-dark; }

    }

  }

}

.filter__btn-view-all {
  margin: 0;
  padding: 0;
  position: absolute;
     @media screen and (min-width: $breakpoint-med) {
    bottom: 0;
  }
    right: 2%;
     @media screen and (max-width: $breakpoint-small) {
      top: 2rem;
     }
  }


// Work

.work__grid {
  display: inline-block;
  margin: 0 1%;
// margin: 0 2%;
  padding: 0;
  width: 98%;
// width: 96%;
   }

.work__thumb {
  display: inline-block;
  float: left;
  margin: 0;
  overflow: hidden;
  padding: 2% 1% 1% 1%;
//   padding: 0;

  a {
    background: $gray-lightest;
    display: block;
    float: left;
    height: inherit !important;
    width: 100%; }

  [data-work-thumb] {
    @include transition(opacity 0.3s ease);

    background-image: none;
    background-color: $gray-lightest;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: inherit;
    position: relative;
    opacity: 1;

    &:hover {
      opacity: 0.5; }

    .work__image {
      filter: grayscale(0%); }

  }

}

.work__title {
  transition: all .3s ease-in-out;
opacity: 0;
    height: 100%;
    @media screen and (max-width: $breakpoint-small) {
      opacity: 1;
    }
  p {
  transition: all .3s ease-in-out;
    opacity: 0;
     bottom: 2.5rem;
     width: 100%;
     margin: 0;
     padding: 0 1rem;
    font-size: 1.5rem;
    position: absolute;
    color: black;
    @media screen and (max-width: $breakpoint-med) {
      font-size: .9rem;
      bottom: 1rem
    }
    @media screen and (max-width: $breakpoint-small) {
      bottom: 0;
     font-size: 1.5rem;
      left: 0;
      padding: 1rem;
      opacity: 1;
      background-color: rgba(255,255,255,.8);

    }
  }
}
.work__title:hover {

  p {

    opacity: 1
  }
      background-color: white;
    opacity: .8;
}

.work__thumb--small, .grid-sizer {
  height: 240px;
  width: 20%; }

.work__thumb--medium {
  height: 240px;
  width: 40%; }

.work__thumb--large {
  height: 480px;
  width: 40%; }

@media screen and (max-width: $breakpoint-med) {

    .work__thumb--small, .work__thumb--medium {
      height: 120px;

      a {
        height: 120px; }

    }

    .work__thumb--large {
      height: 240px;

      a {
        height: 240px; }

    }

  }

@media screen and (max-width: $breakpoint-small) {

  .work__grid {
    margin: 0;
    padding: 0;
    width: 100%; }

  .work__thumb--small,
  .work__thumb--medium,
  .work__thumb--large {
    height: 240px;
    margin: 0 0 2% 0;
    padding: 0;
    width: 100%;

    a {
      height: 240px; }

  }

}

.work__project-nav {
  padding: 20px 0;
  .col-6 {

    @media screen and (max-width: $breakpoint-small) {
    position: absolute;
    pointer-events: none;
  }
  }
  a {
    color: #fff;
    pointer-events: all;
  }

  }

.work__fullsize {
  height: 100vh;
  margin: 0;
  min-height: 100%;
  padding: 0;
  overflow: hidden;
  width: 100%;
    min-width: 100%;

  .work__fullsize__img {
    background-image: none;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    margin: 0;
    min-height: 100%;
    padding: 0;
    position: absolute;
      top: 0;
      left: 0;
    width: 100%; }

}


.center-text-block {
  @media only screen and (min-width: $breakpoint-med) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
}

.contact-info {
   @media only screen and (min-width: $breakpoint-med) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
}

.more-hr {
  text-align: center;
  a {

  color: $gray-regular;
  }
  hr {
    border: solid 1px $gray-light;
    border-bottom: 0px;
  }

}


.accordion {
  list-style: none;
  padding: 0;
  border: 1px solid $gray-light;
  .email {
    display: inline-block;
    margin-top: 2rem;
    a {
      font-weight: 500;
      font-size: .8rem;
    }
  }
  .inner {
    padding: 1rem;
    overflow: hidden;
    display: none;

    &.show {
          /*display: block;*/
        }
      }

      .toggle {
        font-weight: 400;
        color: $gray-dark;

        &.show {
          background-color: $primary;
          color: $white;
          .help {
            color: $white;
            &:after {
              content: "CLOSE";
            }
          }
        }
        .help {
          color: $primary;
          text-transform: uppercase;
          font-weight: 400;
          font-size: .6rem;
          &:after {
            content: "View Job Details";
          }
        }
      }

      li:not(:last-of-type) {
        border-bottom: 1px solid $gray-light;
      }
      li {

        a.toggle {
          width: 100%;
          display: block;
          padding: .75em;
          transition: all .3s ease;

          @media only screen and (min-width: $breakpoint-med) {
            &:hover {
              opacity: .4;
            }
          }
        }
      }
    }

    .accordion--expertise {
      border: none;

      li.col-6 {
        border: 1px solid $gray-light;
        margin-bottom: 2rem;
      }
     li a.toggle {
      padding: 2rem;
      font-size: 1.2rem;
      &.show {
        background-color: $white;
        color: $gray-dark;
        .help {
          color: $primary;
          &:after {
            content: "–";
          }
        }
      }
      .help {
        font-weight: 500;
        font-size: 1.5rem;
        &:after {
          content: "+";
        }
      }
    }

  }

    .news {
      &__container:nth-child(even) {
        @media only screen and (min-width: $breakpoint-med) {
          margin-top: 15rem;
        }
      }
      &__container {
        @media only screen and (max-width: $breakpoint-small) {
          margin-bottom: 3rem !important;
        }
      }
      &__body {
      }
      &__image {
        margin-top: 1rem;
      }
      &__date {
        font-size: .8rem;
        color: $gray-medium;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      &__title {
        margin-top: 0;
        font-weight: 400;
      }
      &__link {
        a {
          font-weight: 500;
          text-transform: uppercase;
        }

      }
    }

    .expertise-hero {
      background-size: 200%;
      background-position: center top;
      background-repeat: no-repeat;
      background-color: black;
        padding-top: 10rem;
        @media only screen and (min-width: $breakpoint-med) {
          padding-top: 0;
      background-size: cover;
    }
      &__block {
        @media only screen and (min-width: $breakpoint-med) {
        padding-top: 12rem;
        margin-bottom: 2rem;
      }
        color: $white;
        font-weight: 400;
        h2 {
          color: $white;
          margin-bottom: 0;
        }
        p {
          margin-top: 0;
          margin-bottom: 0;
        }
        a {
          font-weight: 600;
          font-size: .7rem;
        }
      }
    }

    .project-content {
           @media only screen and (min-width: $breakpoint-med) {
      p {
        margin-top: 0;
      }
      .row {
        margin-bottom: 4rem;
      }
      .stats {
        margin-bottom: 0;
      }
    }
    }


    //mobile nav
.header {
    @media only screen and(max-width: $breakpoint-small) {
        display: none;
    }
}

.header--mobile {
    @media only screen and(min-width: $breakpoint-med) {
        display: none;
    }
    .row {
      margin-bottom: 0 !important;
    }
    padding: 1rem;
    padding-bottom: .8rem;
    position: fixed;
    z-index: 99999;
    background-color: white;
    width: 100vw;
    top: 0;
    box-shadow: rgba(0,0,0,0.2) 0px 2px 20px;
}

.logo--mobile {
    float: right;
    width: 200px;
    margin-top: 0.08rem;
}
.mobile-nav {
    height: 0px;
    overflow: hidden;
    transition: all .7s ease-in-out;

    &.open {
        height: 275px;
    }
    ul {
        list-style-type: none;
        padding: 0;
        li {
            a {
                font-weight: 500;
            }
            font-size: 2rem;
            margin-bottom: 2rem;
        }
    }
}

.drilldown {
    overflow: hidden;
}
.drilldown-sub {
        display: none;

    }
    .drilldown-back {
        margin-bottom: .5rem !important;
        a {
            font-size: .8rem;
        }
    }

.navigation__item {
    a.active {
        border-bottom: 4px solid $primary;
    }
}

.navigation__item--sub {
    margin-bottom: .5rem !important;
    a {
        font-size: 1rem;
    }
    a.active {
        padding-bottom: .1rem;
        border-bottom: 2px solid $primary;
    }
}
.mobile-button {
    padding-top: 2px;
    display: block;
    div {
        width: 25px;
        height: 2px;
        background-color: $primary;
        margin-bottom: 6px;
    }

}
.mobile-nav{
  ul {
  padding-top: 1.2rem;
}

}
//ENd Mobile Nav ^^^
.video {
  margin-bottom: 2rem;
}




.slick-dots {
  li button:before{
    font-size: 1.5rem !important;
    color: $primary !important;
  }
}


.slick-arrow {
  @media only screen and(max-width: $breakpoint-small) {
    display: none !important;
  }
  position: relative;
  z-index: 9000 !important;
  height: 70px !important;
  width: 35px !important;
  &:before {
    content: "" !important;
    position: relative;
    z-index: 9000 !important;
  }
}
.slick-next {
  right: 35px !important;
}



.slick-prev{
  left: 35px !important;
}




.marker {
  transition: all .2s ease-in-out;
  width: 33%;
}
a:hover {
  .marker {
    opacity: .8;
    width: 100% !important;
  }
}


//people modal
.blocker {
  background-color: transparent!important;
}
.person-modal {
  max-width: none !important;
  border-radius: 0px !important;
  padding-top: 0 !important;
box-shadow: 0 0 30px 0 rgba(0,0,0,0.20) !important;
    max-height: 70vh;
    overflow: scroll;
    top: 2rem;
@media only screen and(max-width: $breakpoint-small) {
  top: 100px;
  max-height: 100vh;
  margin-bottom: 5rem;
  }
}

.person-modal__close {
  img {
    width: auto;
  }
  padding-top: 2rem;
  display: inline-block;
  @media only screen and(max-width: $breakpoint-small) {
    position: absolute;
    top:20px;
    right: 0px;
  }
}
.person-modal__details {
  text-align: left;
  .col-11 {
    margin-top: 0;
  }
  h4 {
  border-top: 4px solid $primary;
  display: inline-block;
  padding-top: 2rem;
}

}

.person-modal__info {
  p {
    margin: 0 !important;
  }
}

.home-image {
  @media only screen and(min-width: $breakpoint-med) {
  margin-top: 5.1rem;
}

}
.awards {
  p {
    margin: 0;
  }
}

.company-header {
  text-align: center;
  @media only screen and(min-width: $breakpoint-med) {
    padding-top: 100px;
  }
}

  @media only screen and(min-width: $breakpoint-med) {
.gram {
  margin: 0px 10px 10px 0px !important;
  height: 215px;
  overflow: hidden;
  position: relative;
  img {
    width: auto;
    height: 215px;
  }
}
}
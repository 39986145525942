$font-color-base: $gray-dark;
$font-family-base: 'roboto',
Helvetica,
Arial,
sans-serif;
$font-size-base: 14px;
$font-size-tiny: 11px;
$font-size-small: 13px;
$font-size-medium: 16px;
$font-size-large: 20px;
$font-line-height-small: $font-size-small;
$font-line-height-base: 24px;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-base;
    letter-spacing: .03rem;
}

h1 {
    color: $font-color-base;
    font-size: 48px;
    font-weight: $font-weight-bold;
    line-height: ceil((48px * 1.4));
    text-align: left;
    width: 100%;
    &.slider__title {
        color: #fff;
        text-align: center;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    }
}

h2 {
    color: $gray-dark;
    display: block;
    font-size: 1.5rem;
    line-height: 2rem;
    @media only screen and (min-width: $breakpoint-med) {
        font-size: 2rem;
        //line-height: ceil((36px * 1.4));
        line-height: 2.5rem;
    }
    text-align: left;
    width: 100%;
    font-weight: $font-weight-bold;
    &.quote {
        position: relative;
        line-height: 2.5rem;
        margin-bottom: 0rem;
        &::before,
        &::after {
            content: '"';
        }
        &::before {
            @media only screen and (min-width: $breakpoint-med) {
                position: absolute;
                left: -25px;
            }
            @media only screen and (max-width: $breakpoint-med) {
                padding-right: .5rem;
            }
        }
        &::after {
            position: relative;
            right: -5px;
        }
    }
}

h3 {
    @media only screen and (max-width: $breakpoint-small) {
        margin: 0;
    }
    font-size: $font-size-large;
    font-weight: $font-weight-light;
    line-height: ceil(($font-size-large * 1.4));
    &.slider {
        color: $gray-regular;
        margin: 0 auto;
        position: absolute;
        bottom: 100px;
        text-align: center;
        text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
        text-transform: uppercase;
        width: 100%;
    }
}

h4 {
    font-size: $font-size-medium;
    font-weight: $font-weight-light;
    line-height: 28px;
    &.attribution {
        font-size: 1.3rem;
    }
}

blockquote {
    font-size: .8rem;
    text-align: center;
}

/* Type */

.text--left { text-align: left; }
.text--center { text-align: center; }
.text--right { text-align: right; }
.text--uppercase { text-transform: uppercase; }

/* Lists */

.no-bullet {
  list-style: none;
  margin-left: 0; }

/* */
.no-margin { margin: 0; }
.no-padding { padding: 0; }

.no-border { border: none !important; }

.border--left--gray {
  border-left: 1px solid $gray-lighter; }

.float-right {
    float: right;
}

.hide-for-small {
   @media only screen and (max-width: $breakpoint-small) {
    display: none;
  }
}
.hide-for-medium {
   @media only screen and (min-width: $breakpoint-med) {
    display: none;
  }

}
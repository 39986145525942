// Font Features

@mixin font-feature-settings($feature-list: false) {

	// Only proceed if a list of arguments was passed
	@if $feature-list {

		// Create an empty list for the standard syntax
		$standard: ();

		// Create an empty list for the old Mozilla syntax
		$old-moz: ();

		// Add each listed feature to the standard and old Mozilla values
		@each $feature in $feature-list {
			$standard: append( $standard, '#{$feature}' 1, comma );
			$old-moz: append( $old-moz, '#{$feature}=1', comma );
		}

		// Finally, print out the prefixed and non-prefixed code for all of the listed features
		-moz-font-feature-settings: $old-moz;
		-moz-font-feature-settings: $standard;
		-ms-font-feature-settings: $standard;
		-o-font-feature-settings: $standard;
		-webkit-font-feature-settings: $standard;
		font-feature-settings: $standard;

	}
	
}

@mixin font-display() { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }



// Transitions

@mixin transition($transition...) {
    -moz-transition: $transition;
    -webkit-transition: $transition;
  transition: $transition;
}










* { box-sizing: border-box; }

body {
  @include font-feature-settings(kern liga pnum);
	@include font-display();

  background: #fff;
	color: $font-color-base;
	font-family: $font-family-base;
	font-size: $font-size-base;
	font-weight: $font-weight-light;
	line-height: $font-line-height-base;
  margin: 54px 0 0 0;
   @media only screen and (min-width: $breakpoint-med) {
	margin: 74px 0 0 0;
}
	padding: 0;

  &.home {
    margin-top: 0px; }

	&.company {
    	background: #fff center 100px no-repeat; }

}

a,
a:link,
a:visited,
a:hover,
a:active {
	color: $primary;
	font-weight: $font-weight-light;
	text-decoration: none; }
a.inactive {
  color: $gray-lighter; }

.cta-button {
  color: $primary;
  display: block;
  float: left;
  font-size: $font-size-small;
  font-weight: $font-weight-medium !important;
  margin: 0;
  padding: 20px 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  .marker {
    background: $primary;
    height: 4px;
    position: absolute;
      top: 20%;
      left: 0;
    width: 33%; }

}


.header {
  background: #fff;
  padding: 1rem;
  a {
    padding-bottom: 5px;
  }
  @media only screen and (min-width: $breakpoint-med) {
  padding: 27px 40px 26px 40px;
   a {
      padding-bottom: 27px; }
}
  position: fixed;
    top: 0;
    left: 0;
  //width: 100%;
  width: 100vw;
    //max-width: 1440px;
  z-index: 999;

  a {
    color: $gray-dark; }

  &.header--default {
    border-bottom: 1px solid $gray-lighter;
    box-shadow: 0px 0px 0px rgba(0,0,0,0); }

  &.header--default-no-border {
    border: none;
    box-shadow: 0px 0px 0px rgba(0,0,0,0);



  }

  &.header--scrolled {
    border-bottom-color: transparent; }

  &.header--home {
    background: transparent;

    a {
      color: #fff; }

  }

  &.header--sub {

    padding: 17px 40px 13px 40px;
    position: fixed;
    display: none;
     @media only screen and (min-width: $breakpoint-med) {
      display: block;
      top: 74px;
    }
    z-index: 998; }

}


// Main Navigation

.navigation {
  margin: 0;

  .marker {
    @include transition(opacity 0.3s ease, width 0.3s ease);

    background: $primary;
    height: 4px;
    opacity: 0;
    position: absolute;
      top: 43px;
      left: 0px;
    width: 0%;

    .header--home > & {
      background: #fff; }

  }

  ul {
    display: inline;
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline;
      list-style: none;
      margin-right: 20px;
      position: relative;

      &:last-child {
        margin-right: 0; }

      &:hover > .marker {
        opacity: 1;
        width: 100%; }

      a.active:hover+.marker {
        display: none; }

      a {
        @include transition(color 0.3s ease);

        color: $gray-medium;
        font-size: $font-size-base;
        font-weight: $font-weight-light;
        line-height: $font-size-small;

        &:hover {
          color: $primary; }

        &.active {
          border-bottom: 4px solid $primary;
          color: $gray-dark; }

        .header--home > &:hover {
          color: #fff; }

        .header--default-no-border {
          border: none;
          padding-bottom: 26px; }

      }

    }

  }

}


// Sub Navigation

.navigation--sub {
  display: inline-block;
  margin: 0;

  ul {
    display: inline;
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline;
      list-style: none;
      margin-right: 20px;
      position: relative;

      &:last-child {
        margin-right: 0; }

      a {
        @include transition(color 0.3s ease);

        border: none;
        color: $gray-regular;

        &:hover {
          color: $gray-dark; }

        &.active {
          border: none;
          color: $gray-dark; }

      }

    }

  }

}

img {
  width: 100%;
  height: auto;
}
footer {
  margin: 100px 0 40px 0;
  p {
    font-size: .8rem;
        line-height: 1.2rem;
  }
}

